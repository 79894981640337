<template>
  <EdgeStack
    :id="esId"
    :busy="busy"
    :confirmQuestion="$t('components.edgeStack.confirmQuestion')"
  >
    <template v-slot:header>
      <div class="pb-3 panel-title">{{ getTitleText }}</div>

      <XStepper
        :id="esId"
        :steps="stepperSteps"
        :mode="stepperMode"
        v-model="stepperCurrentStep"
        ref="xstepper"
      />
    </template>

    <template v-slot:footer>
      <XStepperNavigation
        v-if="isEditing"
        :id="esId"
        :steps="stepperSteps"
        :submit-button-text="getSubmitButtonText"
        @submit="onSubmit"
        v-model="stepperCurrentStep"
      />

      <AppButton
        v-if="!isEditing"
        :text="getSubmitButtonText"
        @click="onSubmit({ step: stepperCurrentStep })"
      />
    </template>

    <template v-slot:default>
      <keep-alive>
        <template v-if="stepperCurrentStep === 1">
          <Step1
            :countries="countries"
            :organizations="organizations"
            :primary-key="primaryKeyProxy"
            :form-data="getStep1FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step1"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 2">
          <Step2
            :org-email-settings="orgEmailSettings"
            :primary-key="primaryKeyProxy"
            :form-data="getStep2FormData"
            :isRidingTimeBaseWallet="getRidingTimeBaseWalletStatus"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step2"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 3">
          <Step3
            :primary-key="primaryKeyProxy"
            :orgId="orgIdProxy"
            :payment-gateways="paymentGateways"
            :orgPaymentConfig="orgPaymentConfig"
            :form-data="getStep3FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step3"
          />
        </template>
        <template v-else-if="stepperCurrentStep === 4">
          <Step4
            :primary-key="primaryKeyProxy"
            :form-data="getStep4FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step4"
          />
        </template>
        <!-- <template v-else-if="stepperCurrentStep === 5">
          <Step5
            :primary-key="primaryKeyProxy"
            :form-data="getStep5FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step5"
          />
        </template> -->
      </keep-alive>
    </template>
  </EdgeStack>
</template>

<script>
import pick from 'lodash/pick'
import { useEndpoints } from '@/composables'

import EdgeStack from '@/components/modals/EdgeStack'
import XStepper from '@/components/stepper/XStepper'
import XStepperNavigation from '@/components/stepper/XStepperNavigation'

import AppButton from '@/components/form/AppButton.vue'

import { Step1, Step2, Step3, Step4 } from './add-edit'

export default {
  name: 'FleetAddEdit',

  components: {
    EdgeStack,
    XStepper,
    XStepperNavigation,
    AppButton,
    Step1,
    Step2,
    Step3,
    Step4,
    // Step5,
  },

  props: {
    esId: {
      type: String,
      default: 'fleet-add-edit',
    },
    stepperMode: {
      type: String,
      default: 'free',
    },
    stepperStep: {
      type: Number,
      default: 1,
    },
    primaryKey: {
      required: false,
    },
    rawData: {
      type: Object,
      required: false,
    },
    orgPaymentConfig: {
      type: Object,
      required: false,
    },

    busy: {
      type: Boolean,
      default: false,
    },
    operatorAppEnabled: {
      type: Boolean,
      default: false,
    },
    orgEmailSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    const stepperSteps = [
      {
        title: `${this.$t('components.fleetManagement.addEdit.tabs.details')}`,
        free: true,
      },
      {
        title: `${this.$t(
          'components.fleetManagement.addEdit.tabs.configuration'
        )}`,
        free: false,
      },
      {
        title: `${this.$t('components.fleetManagement.addEdit.tabs.payment')}`,
        free: false,
      },
      {
        title: `${this.$t('components.fleetManagement.addEdit.tabs.schedule')}`,
        free: false,
      },
      // {
      //   title: `${this.$t('components.fleetManagement.addEdit.tabs.operator')}`,
      //   free: false,
      // },
    ]

    // if (!this.operatorAppEnabled) stepperSteps.pop()

    return {
      isLoading: false,
      errors: [],
      // Unless a step is free, user can't navigate to that step by clicking on the step
      // todo: also support this feature on xStepperNavigation & xStepper.navigate() helpers

      stepperSteps,
      // this is the proxy of prop.stepperStep. It's used as v-model for steps
      // it needs to be in sync -> watched
      stepperCurrentStep: null,

      // these data are required for the stepper steps
      countries: [],
      serviceAreas: [],
      organizations: [],
      paymentGateways: [],
      primaryKeyProxy: null,
      orgIdProxy: null,
      isRidingTimeBaseWallet: null,
    }
  },

  computed: {
    // User data is meant to be passed to be in edit mode
    // ? is it better to also check stepperMode? i'll think about it later
    // todo: don't allow navigating to next step in editing mode if current step is dirty
    // todo: -> need to save dirty state with step data, pass prob allowNext & allowPrev on XStepperNavigation component
    isEditing() {
      return !!this.rawData
    },
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getRidingTimeBaseWalletStatus() {
      return this.isRidingTimeBaseWallet
    },

    // getStepperSteps() {
    //   const operatorAppEnabled = this.isEditing
    //     ? [
    //         { title: 'Profile', free: true },
    //         { title: 'Payment', free: true },
    //         { title: 'Document', free: true },
    //         { title: 'Address', free: true },
    //       ]
    //     : [
    //         { title: 'Profile', free: true },
    //         { title: 'Payment', free: false },
    //         { title: 'Document', free: false },
    //         { title: 'Address', free: false },
    //       ]

    //   const operatorAppDisabled = this.isEditing
    //     ? [
    //         { title: 'Profile', free: true },
    //         { title: 'Payment', free: true },
    //         { title: 'Document', free: true },
    //       ]
    //     : [
    //         { title: 'Profile', free: true },
    //         { title: 'Payment', free: false },
    //         { title: 'Document', free: false },
    //       ]

    //   return this.hasOperatorApp ? operatorAppEnabled : operatorAppDisabled
    // },

    getTitleText() {
      return this.isEditing
        ? this.$t('components.fleetManagement.addEdit.headline.update')
        : this.$t('components.fleetManagement.addEdit.headline.add')
    },

    getSubmitButtonText() {
      if (this.stepperCurrentStep > this.stepperSteps.length - 1)
        return this.$t('components.stepNavigation.finish')

      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.saveAndContinue')
    },

    // get steps formData prop getters : either valid object or null.
    // generate from prop.userData for setting edit mode data.
    //
    // pass the computed formData to all steps in all mode & watch prop.formData in step component
    // then set $data.form if props.formData are valid object (not null)
    //
    // the returned object must resemble as the $data.form object defined in different steps
    // it'll be watched & synced in the stepX component.
    getStep1FormData() {
      if (this.rawData) {
        const modelKeys = Object.keys(this.getFormModel({ step: 1 }))
        const picked = pick(this.rawData, modelKeys)

        // override as per requirement
        picked.city = this.rawData.city.id
        picked.country = this.rawData.country.id

        return picked
      }

      return null
    },

    getStep2FormData() {
      if (this.rawData) {
        const modelKeys = Object.keys(this.getFormModel({ step: 2 }))
        const picked = pick(this.rawData, modelKeys)
        picked.currency_symbol = this.rawData.country.currency_symbol

        return picked
      }

      return null
    },
    getStep3FormData() {
      if (this.rawData) {
        const { payment, topup } = this.getFormModel({ step: 3 })
        console.log('topup-t', topup)
        const paymentData = this.rawData?.default_payment_gateway_config
        const paymentModelKeys = Object.keys(payment)

        const pickedPayment = pick(paymentData, paymentModelKeys)
        const topupKeys = Object.keys(topup)
        const pickedTopup = pick(this.rawData, topupKeys)
        console.log('getStep3FormData', paymentData, pickedPayment, pickedTopup)

        return {
          payment: pickedPayment || {},
          paymentId: paymentData && paymentData.id ? paymentData.id : '',
          useOrgPaymentGateway: this.rawData.use_org_payment_gateway_config,
          orgId:
            this.rawData && this.rawData.organization
              ? this.rawData.organization
              : '',
          topup: pickedTopup,
          currencySymbol: this.rawData.country.currency_symbol,
          is_free_ride_allowed: this.rawData.is_free_ride_allowed,
          should_auto_refill_wallet_on_negative_balance: this.rawData
            .should_auto_refill_wallet_on_negative_balance,
          auto_refill_amount: this.rawData.auto_refill_amount,
          is_rider_allowed_to_toggle_auto_refill_setting: this.rawData
            .is_rider_allowed_to_toggle_auto_refill_setting,
          should_apply_extra_charge_for_non_local_rider: this.rawData
            .should_apply_extra_charge_for_non_local_rider,
          extra_charge_percentage_for_non_local_rider: this.rawData
            .extra_charge_percentage_for_non_local_rider,
          fleetCountry: this.rawData.country.name,
        }
      }

      return null
    },

    getStep4FormData() {
      if (this.rawData) {
        const modelKeys = Object.keys(this.getFormModel({ step: 4 }))
        const picked = pick(this.rawData, modelKeys)

        return picked
      }

      return null
    },
    // getStep5FormData() {
    //   if (this.rawData) {
    //     const modelKeys = Object.keys(this.getFormModel({ step: 5 }))
    //     const picked = pick(this.rawData, modelKeys)

    //     return {
    //       data: picked,
    //       currencySymbol: this.rawData.country.currency_symbol,
    //     }
    //   }

    //   return null
    // },
  },

  async created() {
    // todo: use concurrent reqs
    this.countries = await this.$http
      .get(useEndpoints.dropdown.country())
      .then((res) => res.data.data)
      .catch((err) => console.log(err))

    // todo: need dropdown variant from backend
    this.organizations = await this.$http
      .get(useEndpoints.organization.index())
      .then((res) => res.data.data)
      .catch((err) => console.log(err))

    this.serviceAreas = await this.$http
      .get(useEndpoints.dropdown.serviceAreas())
      .then((res) => res.data.data)
      .catch((err) => console.log(err))

    this.paymentGateways = await this.$http
      .get(useEndpoints.dropdown.paymentGateways())
      .then((res) => res.data.data)
      .catch((err) => console.log('paymentGatewayErr', err))

    this.isRidingTimeBaseWallet = await this.$http
      .get(useEndpoints.settings.riderApp.index(this.orgId))
      .then((res) => {
        if (res.data.rider_wallet_balance_type === 1) {
          return true
        } else {
          false
        }
      })
      .catch((err) => console.log('appSettingsErr', err))
  },

  mounted() {
    // reset current step to 1 on closed
    this.$edgeStack.emitter.on(
      this.$edgeStack.getEventName('closed', this.esId),
      () => {
        this.stepperCurrentStep = 1
      }
    )
  },

  beforeDestroy() {
    this.$edgeStack.emitter.off(
      this.$edgeStack.getEventName('closed', this.esId)
    )
  },

  watch: {
    // $data.theUserId needs to be in sync with the prop.userData.id (getUserId),
    // it's used as a proxy var as step 1 need to be able to update it from save event (via onSave)
    //
    // It's required for all steps of edit mode & even in add mode (except step 1 add mode)
    //
    // It's also updated from the save event listener of step 1 via onSave().
    // No need to listen on other step's save event as it should be the same id.
    primaryKey: {
      immediate: true,
      handler(updatedId) {
        // getUserId -> updatedId could be null (in add mode)
        if (updatedId) {
          this.primaryKeyProxy = updatedId
        }
      },
    },

    stepperStep: {
      immediate: true,
      handler(updatedStep) {
        this.stepperCurrentStep = updatedStep
      },
    },
  },

  methods: {
    onDirty(type, id = this.esId) {
      return type === true
        ? this.$edgeStack.shouldConfirm(id)
        : this.$edgeStack.shouldNotConfirm(id)
    },

    onSave(e = { step: null, data: {} }) {
      console.log(e)
      if (e.step === 1) {
        // if it's in add mode, user shouldn't be able to re submit step 1
        // or if the api supports it, it needs to be a patch request

        // if primaryKeyProxy is already set & mode is strict -> it means user is trying
        // to get back to step 1 & resubmit

        // todo: prevent it? 1. check primary key exists in 1st step? make it a patch req

        // update primaryKeyProxy
        this.primaryKeyProxy = e.data.id
        this.orgIdProxy = e.data.organization

        // free the first 2 steps
        const steps = [
          { title: 'Details', free: true },
          { title: 'Configuration', free: true },
          { title: 'Payment', free: true },
          { title: 'Schedule', free: true },
          // { title: 'Operator', free: true },
        ]
        // if (!this.operatorAppEnabled) steps.pop()

        this.$xStepper.defineSteps(this.esId, steps)
        this.beforeNextStep()
      }

      if (e.step === 2) {
        // free the first 3 steps
        const steps = [
          { title: 'Details', free: true },
          { title: 'Configuration', free: true },
          { title: 'Payment', free: true },
          { title: 'Schedule', free: true },
          // { title: 'Operator', free: true },
        ]
        // if (!this.operatorAppEnabled) steps.pop()

        this.$xStepper.defineSteps(this.esId, steps)
        this.beforeNextStep()
      }

      if (e.step === 3) {
        const steps = [
          { title: 'Details', free: true },
          { title: 'Configuration', free: true },
          { title: 'Payment', free: true },
          { title: 'Schedule', free: true },
          // { title: 'Operator', free: true },
        ]
        // if (!this.operatorAppEnabled) steps.pop()

        this.$xStepper.defineSteps(this.esId, steps)
        this.beforeNextStep()
      }
      if (e.step === 4) {
        this.$xStepper.defineSteps(this.esId, this.stepperSteps)
        this.onLastStep()
        // if (this.operatorAppEnabled) {
        //   // free all steps
        //   const steps = [
        //     { title: 'Details', free: true },
        //     { title: 'Configuration', free: true },
        //     { title: 'Payment', free: true },
        //     { title: 'Schedule', free: true },
        //     { title: 'Operator', free: true },
        //   ]

        //   this.$xStepper.defineSteps(this.esId, steps)
        //   this.beforeNextStep()
        // } else {
        //   // free only first step -> as we'r about to reset & close -> set it to initial state
        //   this.$xStepper.defineSteps(this.esId, this.stepperSteps)
        //   this.onLastStep()
        // }
      }

      // if (e.step === 5) {
      //   // free only first step -> as we'r about to reset & close -> set it to initial state
      //   this.$xStepper.defineSteps(this.esId, this.stepperSteps)
      //   this.onLastStep()
      // }
    },

    beforeNextStep() {
      // define what happens about confirmation before exiting on next step (2)
      // on edit mode->after updating->should not confirm unless event triggered by user
      // on add mode->after saving->should confirm
      if (this.stepperMode === 'free') {
        this.$edgeStack.shouldNotConfirm(this.esId)
      } else {
        this.$edgeStack.shouldConfirm(this.esId)
      }

      this.$xStepper.navigate(this.esId).next()
    },

    onLastStep() {
      // notify that the table view needs to be updated

      // Should not confirm as we'r about to close it
      this.$edgeStack.shouldNotConfirm(this.esId)
      this.$edgeStack.close(this.esId)

      this.$edgeStack.emitter.on(
        this.$edgeStack.getEventName('closed', this.esId),
        () => {
          this.stepperCurrentStep = 1
        }
      )
      this.$emit('refresh')
    },

    onSubmit(step) {
      this.$refs[`step${step.step}`].submit()

      // this.$edgeStack.close(this.esId)
    },

    getFormModel({ step }) {
      if (step === 1) {
        return {
          name: '',
          organization: '',

          warehouse_name: '',
          warehouse_lat: '',
          warehouse_lon: '',

          rent_store_address: null,
          use_rent_store_location: null,

          use_company_details: false,

          country: '',
          city: '',
          zip_code: '',
          address: '',
          whatsapp_business_number: '',
          support_phone_number: '',
          registration_no: '',
          tax_id: '',
          brand_logo: null,
        }
      } else if (step === 2) {
        return {
          id: '',
          is_parking_required: false,
          return_vehicle_to_pickup_area: false,
          reservation_time: '',
          reservation_allowed: null,
          on_ride_location_update_frequency: '',
          off_ride_location_update_frequency: '',
          end_ride_picture: null,
          max_speed_limit: '',
          low_speed_limit: null,
          medium_speed_limit: null,
          high_speed_limit: null,
          visibility: '',
          is_group_trip_enabled: null,

          private_code: '',
          private_code_refresh_mins: '',

          email_enabled: false,
          email_address: '',
          email_api_key: '',

          email_is_smtp: false,
          email_host: '',
          email_port: '',
          email_password: '',
          email_encryption: '',
          pause_ride_enabled: '',
          user_init_balance: '',
          invitation_amount: '',
          invitee_invitation_amount: '',
          invitation_required_trips: '',
          riding_time_unit: 1,
          minimum_riding_time_for_topup: 0,
          amount_per_riding_time_unit: '',
          should_apply_tax_on_topup_amount: '',
          topup_tax_percentage: '',
        }
      } else if (step === 3) {
        return {
          payment: {
            payment_gateway: '',
            client_id: '',
            secret_key: '',
            app_key: '',
            extra_key: '',
            sandbox_mode: false,
            sandbox_client_id: '',
            sandbox_secret_key: '',
            sandbox_app_key: '',
            payone_mid: '',
            payone_portalid: '',
            payone_aid: '',
            payone_key: '',
            kkiapay_sandbox_public_key: '',
            kkiapay_sandbox_private_key: '',
            kkiapay_sandbox_secret: '',
            kkiapay_live_public_key: '',
            kkiapay_live_private_key: '',
            kkiapay_live_secret: '',
            supported_payment_method_types: ['Card'],
            src_url: '',
            api_url: '',
          },
          topup: {
            user_deposit: null,
            user_balance_threshold: null,
            user_topup_min_balance: null,
            user_topup_max_balance: null,
            user_topup_daily_limit: null,
          },
          paymentId: '',
          should_auto_refill_wallet_on_negative_balance: false,
          auto_refill_amount: 0,
          is_rider_allowed_to_toggle_auto_refill_setting: false,
        }
      } else if (step === 4) {
        return {
          use_org_business_hours: false,
          business_hour_settings: [],
          org_business_hour_settings: [],
        }
      } else if (step === 5) {
        return {
          charging_task: {
            rate: '',
            point: '',
          },

          rebalance_task: {
            rate: '',
            point: '',
          },
        }
      } else {
        console.error(`Unknown step param! - ${step}`)
      }
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
