var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.isParkingRequired'
            ),"label":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.isParkingRequired'
            ),"rules":"required","placeholder":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.isParkingRequired'
            ),"value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.isParkingRequired'
            ),"options":_vm.getParkingRequiredOptions,"hide-search-box":true},model:{value:(_vm.form.is_parking_required),callback:function ($$v) {_vm.$set(_vm.form, "is_parking_required", $$v)},expression:"form.is_parking_required"}})],1),(_vm.form.is_parking_required)?_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.parkingRulesForVehicle'
            ),"label":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.parkingRulesForVehicle'
            ),"placeholder":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.parkingRulesForVehicle'
            ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.parkingRulesForVehicle'
            ),"hide-search-box":true,"options":[
            { text: 'Park at PickUp Location', value: true },
            { text: 'Park at any parking spot', value: false } ]},model:{value:(_vm.form.return_vehicle_to_pickup_area),callback:function ($$v) {_vm.$set(_vm.form, "return_vehicle_to_pickup_area", $$v)},expression:"form.return_vehicle_to_pickup_area"}})],1):_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.isEndRidePhotoRequired'
            ),"label":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.isEndRidePhotoRequired'
            ),"placeholder":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.isEndRidePhotoRequired'
            ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.isEndRidePhotoRequired'
            ),"options":_vm.getEndRideEnabledOptions,"hide-search-box":true},model:{value:(_vm.form.end_ride_picture),callback:function ($$v) {_vm.$set(_vm.form, "end_ride_picture", $$v)},expression:"form.end_ride_picture"}})],1)]),(_vm.form.is_parking_required)?_c('div',{staticClass:"grid grid-cols-1 gap-4"},[_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.isEndRidePhotoRequired'
            ),"label":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.isEndRidePhotoRequired'
            ),"placeholder":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.isEndRidePhotoRequired'
            ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.isEndRidePhotoRequired'
            ),"options":_vm.getEndRideEnabledOptions,"hide-search-box":true},model:{value:(_vm.form.end_ride_picture),callback:function ($$v) {_vm.$set(_vm.form, "end_ride_picture", $$v)},expression:"form.end_ride_picture"}})],1)]):_vm._e(),_c('div',{staticClass:"py-1"},[_c('TCheckbox',{attrs:{"name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.changeLocationUpdateFrequencyCheckBox'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.changeLocationUpdateFrequencyCheckBox'
          ),"wrapped":""},model:{value:(_vm.update_heartbeat_position_frequency),callback:function ($$v) {_vm.update_heartbeat_position_frequency=$$v},expression:"update_heartbeat_position_frequency"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.locationUpdateFrequencyOnRide'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.locationUpdateFrequencyOnRide'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.locationUpdateFrequencyOnRide'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.locationUpdateFrequencyOnRide'
          ),"options":_vm.getFrequencyOptionsOnRide,"disabled":!_vm.update_heartbeat_position_frequency,"variant":{
          readonly: !_vm.update_heartbeat_position_frequency,
        },"hide-search-box":true},model:{value:(_vm.form.on_ride_location_update_frequency),callback:function ($$v) {_vm.$set(_vm.form, "on_ride_location_update_frequency", $$v)},expression:"form.on_ride_location_update_frequency"}}),_c('AppInput',{attrs:{"disabled":!_vm.update_heartbeat_position_frequency,"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.locationUpdateFrequencyOffRide'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.locationUpdateFrequencyOffRide'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.locationUpdateFrequencyOffRide'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.locationUpdateFrequencyOffRide'
          ),"options":_vm.getFrequencyOptionsOffRide,"variant":{
          readonly: !_vm.update_heartbeat_position_frequency,
        },"hide-search-box":true},model:{value:(_vm.form.off_ride_location_update_frequency),callback:function ($$v) {_vm.$set(_vm.form, "off_ride_location_update_frequency", $$v)},expression:"form.off_ride_location_update_frequency"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.lowSpeedLimit'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.lowSpeedLimit'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.lowSpeedLimit'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.lowSpeedLimit'
          ),"options":_vm.getSpeedLimitOptions,"hide-search-box":true,"haveUnitText":true,"unitText":"km/h"},model:{value:(_vm.form.low_speed_limit),callback:function ($$v) {_vm.$set(_vm.form, "low_speed_limit", $$v)},expression:"form.low_speed_limit"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.mediumSpeedLimit'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.mediumSpeedLimit'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.mediumSpeedLimit'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.mediumSpeedLimit'
          ),"options":_vm.getSpeedLimitOptions,"hide-search-box":true,"haveUnitText":true,"unitText":"km/h"},model:{value:(_vm.form.medium_speed_limit),callback:function ($$v) {_vm.$set(_vm.form, "medium_speed_limit", $$v)},expression:"form.medium_speed_limit"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.highSpeedLimit'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.highSpeedLimit'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.highSpeedLimit'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.highSpeedLimit'
          ),"options":_vm.getSpeedLimitOptions,"hide-search-box":true,"haveUnitText":true,"unitText":"km/h"},model:{value:(_vm.form.high_speed_limit),callback:function ($$v) {_vm.$set(_vm.form, "high_speed_limit", $$v)},expression:"form.high_speed_limit"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.maxSpeedMode'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.maxSpeedMode'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.maxSpeedMode'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.maxSpeedMode'
          ),"options":_vm.getMaxSpeedLimitOptions,"hide-search-box":true},model:{value:(_vm.form.max_speed_limit),callback:function ($$v) {_vm.$set(_vm.form, "max_speed_limit", $$v)},expression:"form.max_speed_limit"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.fleetVisibility'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.fleetVisibility'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.fleetVisibility'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.fleetVisibility'
          ),"options":_vm.getVisibilityOptions,"hide-search-box":true},model:{value:(_vm.form.visibility),callback:function ($$v) {_vm.$set(_vm.form, "visibility", $$v)},expression:"form.visibility"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.isPauseRideEnabled'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.isPauseRideEnabled'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.isPauseRideEnabled'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.isPauseRideEnabled'
          ),"options":_vm.getPauseEnabledOptions,"hide-search-box":true},model:{value:(_vm.form.pause_ride_enabled),callback:function ($$v) {_vm.$set(_vm.form, "pause_ride_enabled", $$v)},expression:"form.pause_ride_enabled"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.allowGroupTrip'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.allowGroupTrip'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","options":_vm.getGroupTripRequiredOptions,"hide-search-box":true},model:{value:(_vm.form.is_group_trip_enabled),callback:function ($$v) {_vm.$set(_vm.form, "is_group_trip_enabled", $$v)},expression:"form.is_group_trip_enabled"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.allowReservationFeature'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.allowReservationFeature'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.allowReservationFeature'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.allowReservationFeature'
          ),"options":_vm.getReservationOptions,"hide-search-box":true},model:{value:(_vm.form.reservation_allowed),callback:function ($$v) {_vm.$set(_vm.form, "reservation_allowed", $$v)},expression:"form.reservation_allowed"}})],1),_c('div',{staticClass:"grid grid-cols-1 "},[(_vm.form.reservation_allowed)?_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.fleetReservationTime'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.fleetReservationTime'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.fleetReservationTime'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.fleetReservationTime'
          ),"options":_vm.getFleetReservationTimeOptions,"hide-search-box":true,"haveUnitText":true,"unitText":"Mins"},model:{value:(_vm.form.reservation_time),callback:function ($$v) {_vm.$set(_vm.form, "reservation_time", $$v)},expression:"form.reservation_time"}}):_vm._e()],1),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.signUpBonus'
        ),"label":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.signUpBonus'
        ),"rules":"required","infoDescription":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.infoDescription.fleetReservationTime'
        ),"haveUnitText":true,"unitText":_vm.form.currency_symbol},model:{value:(_vm.form.user_init_balance),callback:function ($$v) {_vm.$set(_vm.form, "user_init_balance", $$v)},expression:"form.user_init_balance"}}),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.inviterBonus'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.inviterBonus'
          ),"rules":"required","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.inviterBonus'
          ),"haveUnitText":true,"unitText":_vm.form.currency_symbol},model:{value:(_vm.form.invitation_amount),callback:function ($$v) {_vm.$set(_vm.form, "invitation_amount", $$v)},expression:"form.invitation_amount"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.inviteeBonus'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.inviteeBonus'
          ),"rules":"required","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.inviteeBonus'
          ),"haveUnitText":true,"unitText":_vm.form.currency_symbol},model:{value:(_vm.form.invitee_invitation_amount),callback:function ($$v) {_vm.$set(_vm.form, "invitee_invitation_amount", $$v)},expression:"form.invitee_invitation_amount"}})],1),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.numTripsRequiredForReferralBonus'
        ),"label":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.numTripsRequiredForReferralBonus'
        ),"rules":"required","infoDescription":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.infoDescription.numTripsRequiredForReferralBonus'
        )},model:{value:(_vm.form.invitation_required_trips),callback:function ($$v) {_vm.$set(_vm.form, "invitation_required_trips", $$v)},expression:"form.invitation_required_trips"}}),(_vm.isRidingTimeBaseWallet)?_c('div',[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnit'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnit'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.ridingTimingUnit'
          ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.allowReservationFeature'
          ),"options":_vm.getRidingTimeUnitOptions,"hide-search-box":true},model:{value:(_vm.form.riding_time_unit),callback:function ($$v) {_vm.$set(_vm.form, "riding_time_unit", $$v)},expression:"form.riding_time_unit"}}),[(_vm.form.riding_time_unit === 0)?[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"number","name":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnitCost'
                ),"label":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnitCost'
                ),"rules":"required","step":"0.0001","min":"0","haveUnitText":true,"unitText":_vm.getUnitTextForAmountPerRidingTime,"haveSuggestion":_vm.haveAlertForRidingTimeUnitCost,"isSuggestionAlert":_vm.haveAlertForRidingTimeUnitCost,"suggestionText":"Up to 4 decimal places allowed. Example: 0.2345"},model:{value:(_vm.form.amount_per_riding_time_unit),callback:function ($$v) {_vm.$set(_vm.form, "amount_per_riding_time_unit", $$v)},expression:"form.amount_per_riding_time_unit"}}),_c('div',{staticClass:"flex items-center justify-center border-2 border-dashed border-gray-400 rounded-lg bg-gray-100"},[_c('p',{staticClass:"text-xl font-semibold"},[_vm._v(" "+_vm._s(_vm.form.amount_per_riding_time_unit ? _vm.formatNumberWithMaxDecimals( _vm.form.amount_per_riding_time_unit * 60, 4 ) : '--')+" "+_vm._s(((_vm.form.currency_symbol) + " /Minute"))+" ")])])],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('AppInput',{attrs:{"type":"number","name":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.minimumRidingTimeForTopup'
                ),"label":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.minimumRidingTimeForTopup'
                ),"rules":"required","step":"0.1","min":"0"},model:{value:(_vm.form.minimum_riding_time_for_topup),callback:function ($$v) {_vm.$set(_vm.form, "minimum_riding_time_for_topup", $$v)},expression:"form.minimum_riding_time_for_topup"}}),_c('div',{staticClass:"flex items-center justify-center border-2 border-dashed border-gray-400 rounded-lg bg-gray-100"},[_c('p',{staticClass:"text-xl font-semibold"},[_vm._v(" "+_vm._s(_vm.getMinimumRidingTimeTextForTopup)+" ")])])],1)]:_vm._e(),(_vm.form.riding_time_unit === 1)?[_c('AppInput',{attrs:{"type":"number","name":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnitCost'
              ),"label":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnitCost'
              ),"rules":"required","step":"0.0001","min":"0","haveUnitText":true,"unitText":_vm.getUnitTextForAmountPerRidingTime,"haveSuggestion":_vm.haveAlertForRidingTimeUnitCost,"isSuggestionAlert":_vm.haveAlertForRidingTimeUnitCost,"suggestionText":"Up to 4 decimal places allowed. Example: 0.2345"},model:{value:(_vm.form.amount_per_riding_time_unit),callback:function ($$v) {_vm.$set(_vm.form, "amount_per_riding_time_unit", $$v)},expression:"form.amount_per_riding_time_unit"}}),_c('AppInput',{attrs:{"type":"number","name":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.minimumRidingTimeForTopup'
              ),"label":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.minimumRidingTimeForTopup'
              ),"rules":"required","step":"0.01","min":"0"},model:{value:(_vm.form.minimum_riding_time_for_topup),callback:function ($$v) {_vm.$set(_vm.form, "minimum_riding_time_for_topup", $$v)},expression:"form.minimum_riding_time_for_topup"}})]:_vm._e()]],2):_vm._e(),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.applyTaxOnTopup'
        ),"label":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.applyTaxOnTopup'
        ),"value-attribute":"value","text-attribute":"text","options":_vm.getApplyTaxOnTopupOptions,"hide-search-box":true},model:{value:(_vm.form.should_apply_tax_on_topup_amount),callback:function ($$v) {_vm.$set(_vm.form, "should_apply_tax_on_topup_amount", $$v)},expression:"form.should_apply_tax_on_topup_amount"}}),(_vm.form.should_apply_tax_on_topup_amount)?_c('AppInput',{attrs:{"type":"number","name":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.topupTaxRate'
        ),"label":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.topupTaxRate'
        ),"step":"0.01","min":"0","haveUnitText":true,"unitText":"%"},model:{value:(_vm.form.topup_tax_percentage),callback:function ($$v) {_vm.$set(_vm.form, "topup_tax_percentage", $$v)},expression:"form.topup_tax_percentage"}}):_vm._e(),_c('section',[_c('div',{staticClass:"flex items-center my-5 text-sm font-bold text-gray-500"},[_c('span',[_vm._v(_vm._s(_vm.$t( 'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmount' )))]),_c('div',{staticClass:"col-span-1 ml-2 cursor-pointer focus:text-gray-400",on:{"click":_vm.onAddPenaltyTypeField}},[_c('i',{staticClass:"fas fa-plus-circle",staticStyle:{"color":"black"}})])]),_vm._l((_vm.selectedPenaltyTypes),function(item,index){return _c('div',{key:index,staticClass:"flex items-center"},[_c('div',{staticClass:"flex gap-2 items-center w-11/12"},[_c('div',{staticClass:"w-1/2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmountInfo.type'
                ),"label":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmountInfo.type'
                ),"placeholder":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.placeHolder.managePenaltyByAmountInfo.type'
                ),"value-attribute":"id","text-attribute":"title","infoDescription":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.infoDescription.managePenaltyByAmountInfo.type'
                ),"options":_vm.penaltyOptions,"hide-search-box":""},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}})],1),_c('div',{staticClass:"w-1/2"},[_c('AppInput',{attrs:{"type":"number","step":"0.01","rules":"","name":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmountInfo.amount'
                ),"label":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmountInfo.amount'
                ),"placeholder":"e.g. 2","infoDescription":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.infoDescription.managePenaltyByAmountInfo.amount'
                ),"haveUnitText":true,"unitText":_vm.form.currency_symbol},model:{value:(item.deduct_amount),callback:function ($$v) {_vm.$set(item, "deduct_amount", $$v)},expression:"item.deduct_amount"}})],1)]),_c('div',{staticClass:"flex justify-center w-1/12 item-center"},[_c('div',{staticClass:"col-span-1 cursor-pointer focus:text-gray-400",on:{"click":function($event){return _vm.onRemovePenaltyTypeField(index)}}},[_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}})])])])})],2),(_vm.fleetIsPrivate)?[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.privateCode'
            ),"label":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.privateCode'
            ),"infoDescription":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.privateCode'
            )},model:{value:(_vm.form.private_code),callback:function ($$v) {_vm.$set(_vm.form, "private_code", $$v)},expression:"form.private_code"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.privateCodeRefreshTime'
            ),"label":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.title.privateCodeRefreshTime'
            ),"rules":"required","placeholder":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.privateCodeRefreshTime'
            ),"value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.privateCodeRefreshTime'
            ),"options":_vm.getFleetReservationTimeOptions,"hide-search-box":true,"haveUnitText":true,"unitText":"Mins"},model:{value:(_vm.form.private_code_refresh_mins),callback:function ($$v) {_vm.$set(_vm.form, "private_code_refresh_mins", $$v)},expression:"form.private_code_refresh_mins"}})],1)]:_vm._e(),_c('div',{staticClass:"my-4 parentbox"},[_c('TCheckbox',{attrs:{"name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.useOrgEmailConfigCheckbox'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.useOrgEmailConfigCheckbox'
          ),"wrapped":""},model:{value:(_vm.form.email_enabled),callback:function ($$v) {_vm.$set(_vm.form, "email_enabled", $$v)},expression:"form.email_enabled"}})],1),_c('AppInput',{attrs:{"type":"text","name":((_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.emailAddress'
        )) + " " + (_vm.isUsedOtorideDefaultEmailCredential && '(Default)')),"label":((_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.title.emailAddress'
        )) + " " + (_vm.isUsedOtorideDefaultEmailCredential && '(Default)')),"placeholder":"john_doe@gmail.com","infoDescription":_vm.$t(
          'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailAddress'
        ),"disabled":_vm.form.email_enabled},model:{value:(_vm.form.email_address),callback:function ($$v) {_vm.$set(_vm.form, "email_address", $$v)},expression:"form.email_address"}}),(!_vm.isUsedOtorideDefaultEmailCredential)?[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.emailCredentialProvider'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.title.emailCredentialProvider'
          ),"placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.configuration.placeHolder.emailCredentialProvider'
          ),"options":[
          { text: 'SMTP', value: true },
          { text: 'SendGrid', value: false } ],"disabled":_vm.form.email_enabled,"hide-search-box":""},model:{value:(_vm.form.email_is_smtp),callback:function ($$v) {_vm.$set(_vm.form, "email_is_smtp", $$v)},expression:"form.email_is_smtp"}}),(_vm.emailIsSMTP)?[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.emailHost'
              ),"label":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.emailHost'
              ),"placeholder":"e.g. smtp.gmail.com","infoDescription":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailHost'
              ),"disabled":_vm.form.email_enabled},model:{value:(_vm.form.email_host),callback:function ($$v) {_vm.$set(_vm.form, "email_host", $$v)},expression:"form.email_host"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.emailPort'
              ),"label":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.emailPort'
              ),"placeholder":"e.g.587","infoDescription":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailPort'
              ),"disabled":_vm.form.email_enabled},model:{value:(_vm.form.email_port),callback:function ($$v) {_vm.$set(_vm.form, "email_port", $$v)},expression:"form.email_port"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.emailPassword'
              ),"label":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.emailPassword'
              ),"placeholder":"*** *** ***","infoDescription":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailPassword'
              ),"disabled":_vm.form.email_enabled},model:{value:(_vm.form.email_password),callback:function ($$v) {_vm.$set(_vm.form, "email_password", $$v)},expression:"form.email_password"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.emailEncryption'
              ),"label":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.title.emailEncryption'
              ),"placeholder":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.placeHolder.emailEncryption'
              ),"rules":"required","value-attribute":"value","text-attribute":"text","infoDescription":_vm.$t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailEncryption'
              ),"options":_vm.getEmailEncryptionOptions,"disabled":_vm.form.email_enabled,"hide-search-box":true},model:{value:(_vm.form.email_encryption),callback:function ($$v) {_vm.$set(_vm.form, "email_encryption", $$v)},expression:"form.email_encryption"}})],1)]:[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailApiKey'
                ),"label":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailApiKey'
                ),"placeholder":"e.g. xvgsf^$R7865rtxsnb","infoDescription":_vm.$t(
                  'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailApiKey'
                ),"disabled":_vm.form.email_enabled},model:{value:(_vm.form.email_api_key),callback:function ($$v) {_vm.$set(_vm.form, "email_api_key", $$v)},expression:"form.email_api_key"}})],1)])]]:_vm._e(),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }