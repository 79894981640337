<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="grid grid-cols-2 gap-4">
        <div class="w-full">
          <AppInput
            v-model="form.is_parking_required"
            type="richselect"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.isParkingRequired'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.isParkingRequired'
              )
            "
            rules="required"
            :placeholder="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.placeHolder.isParkingRequired'
              )
            "
            value-attribute="value"
            text-attribute="text"
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.isParkingRequired'
              )
            "
            :options="getParkingRequiredOptions"
            :hide-search-box="true"
          />
        </div>
        <div class="w-full" v-if="form.is_parking_required">
          <AppInput
            v-model="form.return_vehicle_to_pickup_area"
            type="richselect"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.parkingRulesForVehicle'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.parkingRulesForVehicle'
              )
            "
            :placeholder="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.placeHolder.parkingRulesForVehicle'
              )
            "
            rules="required"
            value-attribute="value"
            text-attribute="text"
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.parkingRulesForVehicle'
              )
            "
            :hide-search-box="true"
            :options="[
              { text: 'Park at PickUp Location', value: true },
              { text: 'Park at any parking spot', value: false },
            ]"
          />
        </div>
        <div class="w-full" v-else>
          <AppInput
            v-model="form.end_ride_picture"
            type="richselect"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.isEndRidePhotoRequired'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.isEndRidePhotoRequired'
              )
            "
            :placeholder="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.placeHolder.isEndRidePhotoRequired'
              )
            "
            rules="required"
            value-attribute="value"
            text-attribute="text"
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.isEndRidePhotoRequired'
              )
            "
            :options="getEndRideEnabledOptions"
            :hide-search-box="true"
          />
        </div>
      </div>
      <div class="grid grid-cols-1 gap-4" v-if="form.is_parking_required">
        <div class="w-full">
          <AppInput
            v-model="form.end_ride_picture"
            type="richselect"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.isEndRidePhotoRequired'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.isEndRidePhotoRequired'
              )
            "
            :placeholder="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.placeHolder.isEndRidePhotoRequired'
              )
            "
            rules="required"
            value-attribute="value"
            text-attribute="text"
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.isEndRidePhotoRequired'
              )
            "
            :options="getEndRideEnabledOptions"
            :hide-search-box="true"
          />
        </div>
      </div>

      <div class="py-1">
        <TCheckbox
          v-model="update_heartbeat_position_frequency"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.changeLocationUpdateFrequencyCheckBox'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.changeLocationUpdateFrequencyCheckBox'
            )
          "
          wrapped
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.on_ride_location_update_frequency"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.locationUpdateFrequencyOnRide'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.locationUpdateFrequencyOnRide'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.locationUpdateFrequencyOnRide'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.locationUpdateFrequencyOnRide'
            )
          "
          :options="getFrequencyOptionsOnRide"
          :disabled="!update_heartbeat_position_frequency"
          :variant="{
            readonly: !update_heartbeat_position_frequency,
          }"
          :hide-search-box="true"
        />
        <AppInput
          v-model="form.off_ride_location_update_frequency"
          :disabled="!update_heartbeat_position_frequency"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.locationUpdateFrequencyOffRide'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.locationUpdateFrequencyOffRide'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.locationUpdateFrequencyOffRide'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.locationUpdateFrequencyOffRide'
            )
          "
          :options="getFrequencyOptionsOffRide"
          :variant="{
            readonly: !update_heartbeat_position_frequency,
          }"
          :hide-search-box="true"
        />
      </div>

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.low_speed_limit"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.lowSpeedLimit'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.lowSpeedLimit'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.lowSpeedLimit'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.lowSpeedLimit'
            )
          "
          :options="getSpeedLimitOptions"
          :hide-search-box="true"
          :haveUnitText="true"
          unitText="km/h"
        />
        <AppInput
          v-model="form.medium_speed_limit"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.mediumSpeedLimit'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.mediumSpeedLimit'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.mediumSpeedLimit'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.mediumSpeedLimit'
            )
          "
          :options="getSpeedLimitOptions"
          :hide-search-box="true"
          :haveUnitText="true"
          unitText="km/h"
        />
      </div>

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.high_speed_limit"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.highSpeedLimit'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.highSpeedLimit'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.highSpeedLimit'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.highSpeedLimit'
            )
          "
          :options="getSpeedLimitOptions"
          :hide-search-box="true"
          :haveUnitText="true"
          unitText="km/h"
        />

        <AppInput
          v-model="form.max_speed_limit"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.maxSpeedMode'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.maxSpeedMode'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.maxSpeedMode'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.maxSpeedMode'
            )
          "
          :options="getMaxSpeedLimitOptions"
          :hide-search-box="true"
        />
      </div>

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.visibility"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.fleetVisibility'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.fleetVisibility'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.fleetVisibility'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.fleetVisibility'
            )
          "
          :options="getVisibilityOptions"
          :hide-search-box="true"
        />

        <AppInput
          v-model="form.pause_ride_enabled"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.isPauseRideEnabled'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.isPauseRideEnabled'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.isPauseRideEnabled'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.isPauseRideEnabled'
            )
          "
          :options="getPauseEnabledOptions"
          :hide-search-box="true"
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.is_group_trip_enabled"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.allowGroupTrip'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.allowGroupTrip'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :options="getGroupTripRequiredOptions"
          :hide-search-box="true"
        />
        <AppInput
          v-model="form.reservation_allowed"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.allowReservationFeature'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.allowReservationFeature'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.allowReservationFeature'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.allowReservationFeature'
            )
          "
          :options="getReservationOptions"
          :hide-search-box="true"
        />
      </div>
      <div class="grid grid-cols-1 ">
        <AppInput
          v-if="form.reservation_allowed"
          v-model="form.reservation_time"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.fleetReservationTime'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.fleetReservationTime'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.fleetReservationTime'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.fleetReservationTime'
            )
          "
          :options="getFleetReservationTimeOptions"
          :hide-search-box="true"
          :haveUnitText="true"
          unitText="Mins"
        />
      </div>

      <AppInput
        v-model="form.user_init_balance"
        type="text"
        :name="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.title.signUpBonus'
          )
        "
        :label="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.title.signUpBonus'
          )
        "
        rules="required"
        :infoDescription="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.fleetReservationTime'
          )
        "
        :haveUnitText="true"
        :unitText="form.currency_symbol"
      />

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.invitation_amount"
          type="text"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.inviterBonus'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.inviterBonus'
            )
          "
          rules="required"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.inviterBonus'
            )
          "
          :haveUnitText="true"
          :unitText="form.currency_symbol"
        />

        <AppInput
          v-model="form.invitee_invitation_amount"
          type="text"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.inviteeBonus'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.inviteeBonus'
            )
          "
          rules="required"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.inviteeBonus'
            )
          "
          :haveUnitText="true"
          :unitText="form.currency_symbol"
        />
      </div>

      <AppInput
        v-model="form.invitation_required_trips"
        type="text"
        :name="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.title.numTripsRequiredForReferralBonus'
          )
        "
        :label="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.title.numTripsRequiredForReferralBonus'
          )
        "
        rules="required"
        :infoDescription="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.numTripsRequiredForReferralBonus'
          )
        "
      />
      <div v-if="isRidingTimeBaseWallet">
        <AppInput
          v-model="form.riding_time_unit"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnit'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnit'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.ridingTimingUnit'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.infoDescription.allowReservationFeature'
            )
          "
          :options="getRidingTimeUnitOptions"
          :hide-search-box="true"
        />
        <template>
          <template v-if="form.riding_time_unit === 0">
            <div class="grid grid-cols-2 gap-4">
              <AppInput
                v-model="form.amount_per_riding_time_unit"
                type="number"
                :name="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnitCost'
                  )
                "
                :label="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnitCost'
                  )
                "
                rules="required"
                step="0.0001"
                min="0"
                :haveUnitText="true"
                :unitText="getUnitTextForAmountPerRidingTime"
                :haveSuggestion="haveAlertForRidingTimeUnitCost"
                :isSuggestionAlert="haveAlertForRidingTimeUnitCost"
                suggestionText="Up to 4 decimal places allowed. Example: 0.2345"
              />
              <div
                class="flex items-center justify-center border-2 border-dashed border-gray-400 rounded-lg bg-gray-100"
              >
                <p class="text-xl font-semibold">
                  {{
                    form.amount_per_riding_time_unit
                      ? formatNumberWithMaxDecimals(
                          form.amount_per_riding_time_unit * 60,
                          4
                        )
                      : '--'
                  }}
                  {{ `${form.currency_symbol} /Minute` }}
                </p>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-4 mt-2">
              <AppInput
                v-model="form.minimum_riding_time_for_topup"
                type="number"
                :name="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.minimumRidingTimeForTopup'
                  )
                "
                :label="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.minimumRidingTimeForTopup'
                  )
                "
                rules="required"
                step="0.1"
                min="0"
              />
              <div
                class="flex items-center justify-center border-2 border-dashed border-gray-400 rounded-lg bg-gray-100"
              >
                <p class="text-xl font-semibold">
                  {{ getMinimumRidingTimeTextForTopup }}
                </p>
              </div>
            </div>
          </template>
          <template v-if="form.riding_time_unit === 1">
            <AppInput
              v-model="form.amount_per_riding_time_unit"
              type="number"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnitCost'
                )
              "
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.ridingTimingUnitCost'
                )
              "
              rules="required"
              step="0.0001"
              min="0"
              :haveUnitText="true"
              :unitText="getUnitTextForAmountPerRidingTime"
              :haveSuggestion="haveAlertForRidingTimeUnitCost"
              :isSuggestionAlert="haveAlertForRidingTimeUnitCost"
              suggestionText="Up to 4 decimal places allowed. Example: 0.2345"
            />

            <AppInput
              v-model="form.minimum_riding_time_for_topup"
              type="number"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.minimumRidingTimeForTopup'
                )
              "
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.minimumRidingTimeForTopup'
                )
              "
              rules="required"
              step="0.01"
              min="0"
            />
          </template>
        </template>
      </div>
      <AppInput
        v-model="form.should_apply_tax_on_topup_amount"
        type="richselect"
        :name="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.title.applyTaxOnTopup'
          )
        "
        :label="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.title.applyTaxOnTopup'
          )
        "
        value-attribute="value"
        text-attribute="text"
        :options="getApplyTaxOnTopupOptions"
        :hide-search-box="true"
      />
      <AppInput
        v-if="form.should_apply_tax_on_topup_amount"
        v-model="form.topup_tax_percentage"
        type="number"
        :name="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.title.topupTaxRate'
          )
        "
        :label="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.title.topupTaxRate'
          )
        "
        step="0.01"
        min="0"
        :haveUnitText="true"
        unitText="%"
      />
      <!-- end 3 -->
      <!-- penalty point -->
      <section>
        <div class="flex items-center my-5 text-sm font-bold text-gray-500">
          <span>{{
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmount'
            )
          }}</span>
          <div
            class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
            @click="onAddPenaltyTypeField"
          >
            <i class="fas fa-plus-circle" style="color: black"></i>
          </div>
        </div>
        <!-- {{ penalty Types }} -->
        <div
          class="flex items-center"
          v-for="(item, index) in selectedPenaltyTypes"
          :key="index"
        >
          <div class="flex gap-2 items-center w-11/12">
            <div class="w-1/2">
              <AppInput
                v-model="item.id"
                type="richselect"
                :name="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmountInfo.type'
                  )
                "
                :label="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmountInfo.type'
                  )
                "
                :placeholder="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.placeHolder.managePenaltyByAmountInfo.type'
                  )
                "
                value-attribute="id"
                text-attribute="title"
                :infoDescription="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.infoDescription.managePenaltyByAmountInfo.type'
                  )
                "
                :options="penaltyOptions"
                hide-search-box
              />
            </div>

            <div class="w-1/2">
              <AppInput
                v-model="item.deduct_amount"
                type="number"
                step="0.01"
                rules=""
                :name="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmountInfo.amount'
                  )
                "
                :label="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.managePenaltyByAmountInfo.amount'
                  )
                "
                placeholder="e.g. 2"
                :infoDescription="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.infoDescription.managePenaltyByAmountInfo.amount'
                  )
                "
                :haveUnitText="true"
                :unitText="form.currency_symbol"
              />
            </div>
          </div>

          <div class="flex justify-center w-1/12 item-center">
            <div
              class="col-span-1 cursor-pointer focus:text-gray-400"
              @click="onRemovePenaltyTypeField(index)"
            >
              <i class="fas fa-minus-circle" style="color: #d90a20"></i>
            </div>
          </div>
        </div>
      </section>
      <!-- /penalty point -->

      <template v-if="fleetIsPrivate">
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.private_code"
            type="text"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.privateCode'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.privateCode'
              )
            "
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.privateCode'
              )
            "
          />

          <AppInput
            v-model="form.private_code_refresh_mins"
            type="richselect"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.privateCodeRefreshTime'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.title.privateCodeRefreshTime'
              )
            "
            rules="required"
            :placeholder="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.placeHolder.privateCodeRefreshTime'
              )
            "
            value-attribute="value"
            text-attribute="text"
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.configuration.infoDescription.privateCodeRefreshTime'
              )
            "
            :options="getFleetReservationTimeOptions"
            :hide-search-box="true"
            :haveUnitText="true"
            unitText="Mins"
          />
        </div>
      </template>

      <div class="my-4 parentbox">
        <!-- <div
          v-if="!isOrgEmailSettingsOkay"
          class="childbox"
          @click.once="showErrorMsg"
        ></div> -->
        <TCheckbox
          v-model="form.email_enabled"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.useOrgEmailConfigCheckbox'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.useOrgEmailConfigCheckbox'
            )
          "
          wrapped
        />

        <!-- <small v-show="showMsg" class="text-xs text-red-600">
          You haven't set default organization email settings
          <span class="font-bold">{{
            `(email ${emailMissingSettings.join(',')})`
          }}</span>
          properly . Please update from
          <router-link
            :to="{ name: 'EmailSettings' }"
            :target="`_blank`"
            :class="`text-blue-800 `"
          >
            here
          </router-link>
          .
        </small> -->
      </div>

      <AppInput
        v-model="form.email_address"
        type="text"
        :name="
          `${$t(
            'components.fleetManagement.addEdit.steps.configuration.title.emailAddress'
          )} ${isUsedOtorideDefaultEmailCredential && '(Default)'}`
        "
        :label="
          `${$t(
            'components.fleetManagement.addEdit.steps.configuration.title.emailAddress'
          )} ${isUsedOtorideDefaultEmailCredential && '(Default)'}`
        "
        placeholder="john_doe@gmail.com"
        :infoDescription="
          $t(
            'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailAddress'
          )
        "
        :disabled="form.email_enabled"
      />

      <template v-if="!isUsedOtorideDefaultEmailCredential">
        <AppInput
          v-model="form.email_is_smtp"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.emailCredentialProvider'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.title.emailCredentialProvider'
            )
          "
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.configuration.placeHolder.emailCredentialProvider'
            )
          "
          :options="[
            { text: 'SMTP', value: true },
            { text: 'SendGrid', value: false },
          ]"
          :disabled="form.email_enabled"
          hide-search-box
        />
        <template v-if="emailIsSMTP">
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.email_host"
              type="text"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailHost'
                )
              "
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailHost'
                )
              "
              placeholder="e.g. smtp.gmail.com"
              :infoDescription="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailHost'
                )
              "
              :disabled="form.email_enabled"
            />

            <AppInput
              v-model="form.email_port"
              type="text"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailPort'
                )
              "
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailPort'
                )
              "
              placeholder="e.g.587"
              :infoDescription="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailPort'
                )
              "
              :disabled="form.email_enabled"
            />
          </div>

          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.email_password"
              type="text"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailPassword'
                )
              "
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailPassword'
                )
              "
              placeholder="*** *** ***"
              :infoDescription="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailPassword'
                )
              "
              :disabled="form.email_enabled"
            />

            <AppInput
              v-model="form.email_encryption"
              type="richselect"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailEncryption'
                )
              "
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.title.emailEncryption'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.placeHolder.emailEncryption'
                )
              "
              rules="required"
              value-attribute="value"
              text-attribute="text"
              :infoDescription="
                $t(
                  'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailEncryption'
                )
              "
              :options="getEmailEncryptionOptions"
              :disabled="form.email_enabled"
              :hide-search-box="true"
            />
          </div>
        </template>
        <template v-else>
          <div class="flex items-center">
            <div class="w-full">
              <AppInput
                v-model="form.email_api_key"
                type="text"
                :name="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.emailApiKey'
                  )
                "
                :label="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.title.emailApiKey'
                  )
                "
                placeholder="e.g. xvgsf^$R7865rtxsnb"
                :infoDescription="
                  $t(
                    'components.fleetManagement.addEdit.steps.configuration.infoDescription.emailApiKey'
                  )
                "
                :disabled="form.email_enabled"
              />
            </div>
          </div>
        </template>
      </template>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { deepCompareObjects } from '@/utils'
import { useEndpoints } from '@/composables'
import { formatNumberWithMaxDecimals } from '@/utils/Helpers.js'

export default {
  name: 'Step2',

  components: {},

  props: {
    primaryKey: {
      required: true,
    },
    orgEmailSettings: {
      type: Object,
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRidingTimeBaseWallet: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      saveCounter: 0,
      showMsg: false,
      emailMissingSettings: [],
      penaltyOptions: [],
      allPenaltyTypes: [],
      selectedPenaltyTypes: [],
      update_heartbeat_position_frequency: false,

      form: {
        id: '',
        currency_symbol: '',
        is_parking_required: '',
        return_vehicle_to_pickup_area: null,
        reservation_time: '',
        reservation_allowed: null,
        pause_ride_enabled: '',
        user_init_balance: '',
        invitation_amount: '',
        invitee_invitation_amount: '',
        amount_per_riding_time_unit: '',
        should_apply_tax_on_topup_amount: '',
        topup_tax_percentage: '',
        invitation_required_trips: '',
        on_ride_location_update_frequency: 30,
        off_ride_location_update_frequency: 240,
        end_ride_picture: '',
        max_speed_limit: '',
        low_speed_limit: null,
        medium_speed_limit: null,
        high_speed_limit: null,
        visibility: '',
        is_group_trip_enabled: null,

        private_code: '',
        private_code_refresh_mins: '',

        email_enabled: '',
        email_address: '',

        email_api_key: '',

        email_is_smtp: '',
        email_host: '',
        email_port: '',
        email_password: '',
        email_encryption: '',
      },
      previousEmailSettings: {
        email_address: '',
        email_api_key: '',
        email_is_smtp: '',
        email_host: '',
        email_port: '',
        email_password: '',
        email_encryption: '',
      },
      haveAlertForRidingTimeUnitCost: false,
    }
  },

  computed: {
    // getPenaltyOptions() {
    //   return this.penaltyOptions.filter(
    //     (pE) => !this.selectedPenaltyTypes.some((sE) => pE.id === sE.id)
    //   )
    // },
    isUsedOtorideDefaultEmailCredential() {
      return (
        this.$store.getters['auth/organizationInfo'].subscription
          .should_use_otoride_email_credentials || false
      )
    },
    isOrgEmailSettingsOkay() {
      const s = this.orgEmailSettings
      // check if all settings item has a value
      let flag = Object.keys(s).length !== 0

      for (const k in s) {
        if (s[k] === 'null' || s[k] === '' || s[k] === null) {
          flag = false
          let name
          if (k === 'email_is_smtp') {
            name = 'SMTP decision'
          } else if (k === 'email_api_key') {
            name = 'API key'
          } else {
            name = k.split('_')[1]
          }
          this.emailMissingData(name)
        }
      }
      return flag
    },

    emailEnabled() {
      return this.form.email_enabled
    },

    emailIsSMTP() {
      return this.form.email_is_smtp
    },

    fleetIsPrivate() {
      return this.form.visibility === 'PR'
    },

    getParkingRequiredOptions() {
      return [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ]
    },
    getGroupTripRequiredOptions() {
      return [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ]
    },
    getReservationOptions() {
      return [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ]
    },

    getPauseEnabledOptions() {
      return [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ]
    },
    getEndRideEnabledOptions() {
      return [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ]
    },
    getApplyTaxOnTopupOptions() {
      return [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ]
    },
    getRidingTimeUnitOptions() {
      return [
        { value: 0, text: 'Second' },
        { value: 1, text: 'Minute' },
      ]
    },
    getUnitTextForAmountPerRidingTime() {
      let unitText = ''
      if (this.form.riding_time_unit === 0) {
        unitText = 'Sec'
      }

      if (this.form.riding_time_unit === 1) {
        unitText = 'Min'
      }
      return `${this.form.currency_symbol} /${unitText}`
    },

    getVisibilityOptions() {
      return [
        { value: 'PU', text: 'Public' },
        { value: 'PR', text: 'Private' },
      ]
      // ("PU", "Public"),
      // ("PR", "Private"),
      // ("KP", "Keep")
    },

    getFleetReservationTimeOptions() {
      return [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
        { value: 20, text: '20' },
        { value: 25, text: '25' },
        { value: 30, text: '30' },
      ]
    },

    getMaxSpeedLimitOptions() {
      return [
        { value: 'LOW', text: 'Low' },
        { value: 'MEDIUM', text: 'Medium' },
        { value: 'HIGH', text: 'High' },
      ]
    },
    getSpeedLimitOptions() {
      return [
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
        { value: 11, text: 11 },
        { value: 12, text: 12 },
        { value: 13, text: 13 },
        { value: 14, text: 14 },
        { value: 15, text: 15 },
        { value: 16, text: 16 },
        { value: 17, text: 17 },
        { value: 18, text: 18 },
        { value: 19, text: 19 },
        { value: 20, text: 20 },
        { value: 21, text: 21 },
        { value: 22, text: 22 },
        { value: 23, text: 23 },
        { value: 24, text: 24 },
        { value: 25, text: 25 },
      ]
    },

    getFrequencyOptionsOnRide() {
      return [
        { value: 10, text: '10s' },
        { value: 20, text: '20s' },
        { value: 30, text: '30s' },
        { value: 60, text: '1m' },
        { value: 90, text: '1m 30s' },
        { value: 120, text: '2m' },
        { value: 150, text: '2m 30s' },
        { value: 180, text: '3m' },
        { value: 210, text: '3m 30s' },
        { value: 240, text: '4m' },
      ]
    },
    getFrequencyOptionsOffRide() {
      return [
        { value: 30, text: '30s' },
        { value: 60, text: '1m' },
        { value: 90, text: '1m 30s' },
        { value: 120, text: '2m' },
        { value: 150, text: '2m 30s' },
        { value: 180, text: '3m' },
        { value: 210, text: '3m 30s' },
        { value: 240, text: '4m' },
      ]
    },

    getEmailEncryptionOptions() {
      return [
        { value: 'TLS', text: 'TLS' },
        { value: 'SSL', text: 'SSL' },
        { value: 'None', text: 'None' },
      ]
    },
    getMinimumRidingTimeTextForTopup() {
      let text = ''
      if (this.form.riding_time_unit === 0) {
        if (this.form.minimum_riding_time_for_topup < 60) {
          text = `${this.form.minimum_riding_time_for_topup} Sec`
        }
        if (this.form.minimum_riding_time_for_topup >= 60) {
          const minute = Math.floor(
            parseInt(this.form.minimum_riding_time_for_topup) / 60
          )
          const second = parseInt(this.form.minimum_riding_time_for_topup) % 60
          if (minute > 0 && second > 0) {
            text = `${minute} Min ${second} Sec`
          }
          if (minute > 0 && second === 0) {
            text = `${minute} Minute`
          }
        }
      }
      return text
    },
  },

  watch: {
    // sync props.formData with $data.from
    'formData': {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          if (!data.email_enabled) {
            data.email_enabled = true
          } else {
            data.email_enabled = false
          }
          this.form = { ...data }
          this.previousEmailSettings.email_address = data.email_address
          this.previousEmailSettings.email_api_key = data.email_api_key
          this.previousEmailSettings.email_is_smtp = data.email_is_smtp
          this.previousEmailSettings.email_host = data.email_host
          this.previousEmailSettings.email_port = data.email_port
          this.previousEmailSettings.email_password = data.email_password
          this.previousEmailSettings.email_encryption = data.email_encryption
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    'form': {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 2, data: this.form })
      },
    },

    'emailEnabled': {
      immediate: false,
      deep: false,
      handler(val) {
        if (this.saveCounter === 0) {
          if (val) {
            const s = this.orgEmailSettings
            this.form.email_address = s.email_address
            this.form.email_api_key = s.email_api_key
            this.form.email_is_smtp = s.email_is_smtp
            this.form.email_host = s.email_host
            this.form.email_port = s.email_port
            this.form.email_password = s.email_password
            this.form.email_encryption = s.email_encryption
          } else {
            this.form.email_address = this.previousEmailSettings.email_address
            this.form.email_api_key = this.previousEmailSettings.email_api_key
            this.form.email_is_smtp = this.previousEmailSettings.email_is_smtp
            this.form.email_host = this.previousEmailSettings.email_host
            this.form.email_port = this.previousEmailSettings.email_port
            this.form.email_password = this.previousEmailSettings.email_password
            this.form.email_encryption = this.previousEmailSettings.email_encryption
          }
        }
      },
    },
    'form.amount_per_riding_time_unit': {
      immediate: true,
      deep: false,
      handler(val) {
        console.log(
          'amount_per_riding_time_unit',
          val.toString().split('.')[1].length
        )
        if (val.toString().split('.')[1].length > 4) {
          this.haveAlertForRidingTimeUnitCost = true
        } else {
          this.haveAlertForRidingTimeUnitCost = false
        }
      },
    },
  },
  created() {
    this.saveCounter = 0

    this.$http
      .get(useEndpoints.fleet.penaltyIndex(this.form.id))
      .then((res) => {
        this.allPenaltyTypes = res.data.data
        this.selectedPenaltyTypes = res.data.data.filter(
          (item) => parseFloat(item.deduct_amount) > 0
        )
        this.penaltyOptions = res.data.data.map((item) => {
          return { id: item.id, title: item.title }
        })
      })
  },
  methods: {
    formatNumberWithMaxDecimals,
    onAddPenaltyTypeField() {
      if (this.selectedPenaltyTypes.length < this.allPenaltyTypes.length) {
        this.selectedPenaltyTypes.unshift({ id: '', deduct_amount: '0.00' })
      } else {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'CAUTION',
            text: 'No more policy have been left',
          },
          3000
        )
      }
    },
    onRemovePenaltyTypeField(index) {
      this.selectedPenaltyTypes.splice(index, 1)
    },
    showErrorMsg() {
      this.showMsg = true
    },
    emailMissingData(name) {
      this.emailMissingSettings.push(name)
    },
    async submit() {
      this.$refs.submitButton.click()
    },
    async submitStep() {
      const method = 'PATCH'
      if (this.selectedPenaltyTypes.length) {
        //selected
        this.selectedPenaltyTypes.forEach((element) => {
          const penaltyUrl = useEndpoints.fleet.penaltiesUpdate(element.id)
          const penaltyData = new FormData()
          penaltyData.append('deduct_amount', element.deduct_amount)
          this.$http({
            url: penaltyUrl,
            method: 'PATCH',
            data: penaltyData,
          })
        })

        //another
        const unselectedPenaltyTypes = this.allPenaltyTypes.filter(
          (aE) => !this.selectedPenaltyTypes.some((sE) => aE.id === sE.id)
        )
        unselectedPenaltyTypes.forEach((element) => {
          const penaltyUrl = useEndpoints.fleet.penaltiesUpdate(element.id)
          const penaltyData = new FormData()
          penaltyData.append('deduct_amount', 0)
          this.$http({
            url: penaltyUrl,
            method: 'PATCH',
            data: penaltyData,
          })
        })
      } else {
        this.allPenaltyTypes.forEach((element) => {
          const penaltyUrl = useEndpoints.fleet.penaltiesUpdate(element.id)
          const penaltyData = new FormData()
          penaltyData.append('deduct_amount', 0)
          this.$http({
            url: penaltyUrl,
            method: 'PATCH',
            data: penaltyData,
          })
        })
      }
      const url = useEndpoints.fleet.update(this.primaryKey)
      if (!this.form.email_enabled) {
        this.form.email_enabled = true
      } else {
        this.form.email_enabled = false
      }

      const formDataProxy = {
        ...this.form,
        update_heartbeat_position_frequency: this
          .update_heartbeat_position_frequency,
      }
      console.log('formDataProxy', formDataProxy)
      const propertyNames = Object.keys(formDataProxy)
      propertyNames.map((name) => {
        if (formDataProxy[name] === null) {
          delete formDataProxy[name]
        }
      })

      if (method !== 'PATCH') {
        delete formDataProxy.update_heartbeat_position_frequency
      }
      // delete optional fields based on context
      if (this.emailIsSMTP) {
        delete formDataProxy.email_api_key
      } else {
        delete formDataProxy.email_host
        delete formDataProxy.email_port
        delete formDataProxy.email_password
        delete formDataProxy.email_encryption
      }

      // delete optional fields based on context
      if (this.fleetIsPrivate === false) {
        delete formDataProxy.private_code
        delete formDataProxy.private_code_refresh_mins
      }

      const data = new FormData()
      for (const key in formDataProxy) {
        data.append(key, formDataProxy[key])
      }
      //---start
      this.saveCounter++
      this.form.email_enabled = !this.form.email_enabled //just for matching UI sense
      //---end
      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 2, data: res.data })

          const message = `Organization User ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.status,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.update_heartbeat_position_frequency = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 22px;
  font-weight: bold;
  color: #2e2e39;
}
.parentbox {
  position: relative;
}
.childbox {
  position: absolute;
  width: 1rem;
  height: 100%;
}
</style>
